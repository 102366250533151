<template>
  <b-modal
    id="global-market-modal"
    ref="global-market-modal"
    hide-footer
    title="เลือกชนิดหวย"
    @show="resetModal"
    @hidden="resetModal"
    size="xl"
  >
    <div class="row">
      <div class="col-md-4">
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            button
            v-for="item in groups"
            :key="item._id"
            :variant="selectGroupId===item._id?'primary':''"
            @click="toggleGroupId(item._id)"
            v-if="item.length"
          >
            <span>
              {{item.groupTitle}}
              <b-badge variant="primary" pill>{{item.length}}</b-badge>
            </span>
            <i class="fas fa-chevron-right"></i>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-md-8">
        <b-list-group>
          <b-list-group-item
            button
            v-for="item in markets"
            :key="item._id"
            :variant="currentMarketId===item._id?'success':''"
            @click="toggleMarketId(item._id)"
          >
            <span>
              {{item.marketTitle}}
            </span>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'GlobalMarketModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectGroupId: this.$store.state.globalMarket.groupId,
      isReload: false
    }
  },
  computed: {
    groups() {
      return this.$store.state.marketGroups.filter((group)=>{
        return true //group.status === 'Active'
      })
      .map((group)=>{
        return {
          _id: group._id,
          groupTitle: group.groupTitle,
          length: this.totalMarketInGroup(group._id)
        }
      })
    },
    currentGroupId() {
      return this.$store.state.globalMarket.groupId
    },
    markets() {
      return this.$store.state.markets.filter(market => market.groupId === this.selectGroupId)
    },
    currentMarketId() {
      return this.$store.state.globalMarket.marketId
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.selectGroupId = this.$store.state.globalMarket.groupId
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    currentGroupId() {
      this.selectGroupId = this.currentGroupId
    }
  },
  methods: {
    resetModal() {},
    showModal() {
      this.isReload = false
      this.$refs['global-market-modal'].show()
    },
    hideModal() {
      this.$refs['global-market-modal'].hide()
    },
    totalMarketInGroup(groupId) {
      const markets = (this.$store.state.markets || []).filter(market=>market.groupId===groupId)
      return markets.length
    },
    toggleGroupId(groupId) {
      this.selectGroupId = groupId
    },
    toggleMarketId(marketId) {
      if(this.currentMarketId !== marketId) {
        this.isReload = true
      }
      this.$store.commit('updateGlobalMarket', {
        groupId: this.selectGroupId,
        marketId: marketId
      })
      this.$refs['global-market-modal'].hide()
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      console.log('modalId', modalId)
      if(modalId === 'global-market-modal')
        this.$emit('close', this.isReload)
    })
  }
}
</script>
