import 'regenerator-runtime/runtime'

import api from "@/services/apiService"

class MarketService {

  /**
   * ดึงรายการงวดหวยล่าสุด
   */
  getRound(marketId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market/round/' + marketId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงรายการงวดหวยทั้งหมด
   */
  getRounds(marketId) {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market/rounds/' + marketId)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงการตั้งค่ากลุ่มของ บริษัท
   */
  getSettingGroups() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_BACKEND_API + 'market/setting-groups')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  saveSettingBetLimit(groupId, data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'market/setting-bet-limit/' + groupId, data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

}

export default new MarketService()
