<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink class="is-header-profile">
        <div class="c-avatar">
          <img
            src="/img/avatars/1.jpg"
            class="c-avatar-img"
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <!--<CDropdownItem>
      <CIcon name="cil-user" /> Profile
    </CDropdownItem>-->
    <CDropdownItem @click="isChangePassword = !isChangePassword">
      <CIcon name="cil-settings" /> เปลี่ยนรหัสผ่าน
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
    <ChangePasswordModal :is-show="isChangePassword" @close="isChangePassword = false" />
  </CDropdown>
</template>

<script>
import AuthService from '@/services/authService'

import ChangePasswordModal from '@/views/components/ChangePasswordModal'

export default {
  name: 'TheHeaderDropdownAccnt',
  components: {
    ChangePasswordModal
  },
  data () {
    return { 
      isChangePassword: false
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    }
  },
  methods: {
    logout() {
      AuthService.logout()
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
