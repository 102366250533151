<template>
	<b-modal 
		ref="password-modal" 
		no-close-on-backdrop 
		hide-footer
		title="เปลี่ยนรหัสผ่าน" 
		ok-variant="success" 
		ok-title="เปลี่ยนรหัสผ่าน" 
		cancel-title="ยกเลิก" 
		footer-class="justify-content-between"
		@show="resetModal"
    @hidden="resetModal"
	>
    <form 
    	ref="form" 
    	@submit.stop.prevent="handleSubmit"
    >

    	<b-form-group
        label="รหัสผ่านเดิม"
        label-for="input-old-password"
      >
        <b-form-input
          id="input-old-password"
          v-model="password.old_password"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รหัสผ่านใหม่"
        label-for="input-new-password"
      >
        <b-form-input
          id="input-new-password"
          v-model="password.new_password"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="ยืนยันรหัสผ่าน"
        label-for="input-confirm-password"
      >
        <b-form-input
          id="input-confirm-password"
          v-model="password.confirm_password"
          type="password"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="danger" block pill :disabled="isProcess">{{isProcess ? 'กำลังบันทึกรหัสผ่าน...' : 'เปลี่ยนรหัสผ่าน'}}</b-button>

    </form>
  </b-modal>
</template>
<script>
import AuthService from '@/services/authService'

import firebase from 'firebase/app'
import 'firebase/auth'

import Swal from 'sweetalert2'

export default {
	name: 'ChangePasswordModal',
	props: {
		isShow: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isProcess: false,
			password: {
				old_password: '',
        new_password: '',
        confirm_password: ''
			}
		}
	},
	watch: {
		isShow() {
			if(this.isShow){
				this.showModal()
			}else{
				this.hideModal()
			}
		}
	},
	methods: {
		resetModal() {
			this.isProcess = false
			this.password = {
				old_password: '',
        new_password: '',
        confirm_password: ''
			}
		},
		showModal() {
      this.$refs['password-modal'].show()
    },
    hideModal() {
      this.$refs['password-modal'].hide()
    },
    handleSubmit() {

    	if(this.password.new_password !== this.password.confirm_password) {
    		Swal.fire({
          text: 'ยืนยันรหัสผ่านไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then((res)=>{
        	this.password.confirm_password = ''
        })
    		return
    	}
    	
    	let user = firebase.auth().currentUser;
      console.log('user', user)
      let self = this
      this.isProcess = true

      firebase
      .auth()
      .signInWithEmailAndPassword(user.email, this.password.old_password)
      .then(async () => {

        firebase.auth().currentUser.updatePassword(this.password.new_password).then(function() {
          // Update successful.
          self.$nextTick(() => {
		        self.$refs['password-modal'].hide()
		      })
          Swal.fire({
	        	title: 'สำเร็จ!',
	          text: 'เปลี่ยนรหัสผ่านเสร็จเรียบร้อย กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
	          icon: 'success',
	          confirmButtonText: 'OK'
	        }).then((result)=>{
	        	AuthService.logout()
	        })
        })
        .catch((err) => {
          self.$nextTick(() => {
		        self.$refs['password-modal'].hide()
		      })
          Swal.fire({
          	title: 'ผิดพลาด!',
	          text: 'เปลี่ยนรหัสผ่านไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
	          icon: 'error',
	          confirmButtonText: 'OK'
	        })
        })
        .finally(()=>{
        	self.isProcess = false
        	console.log('finally')
        })
      })
      .catch(() => {
      	self.$nextTick(() => {
	        self.$refs['password-modal'].hide()
	      })
      	Swal.fire({
        	title: 'ผิดพลาด!',
          text: 'รหัสผ่านปัจจุบันไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    }
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
	}
}
</script>