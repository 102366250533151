export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายการหวย',
        route: '/manage-lotto',
        icon: 'cil-list',
        items: [
          {
            name: 'หวยประจำวัน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/daily'
          },
          {
            name: 'รอออกผล',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/wait-result'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการหวย',
        route: '/manage-lotto',
        fontIcon: 'fas fa-award',
        items: [
          {
            name: 'ตั้งค่างวดหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/setting'
          },
          // {
          //   name: 'ออกผล',
          //   fontIcon: 'fas fa-angle-double-right',
          //   to: '/manage-lotto/award'
          // },
          {
            name: 'อัพเดตผล',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/update'
          },
          {
            name: 'โพยถูกรางวัล',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/won-ticket'
          },
          // {
          //   name: 'จ่ายเงิน',
          //   fontIcon: 'fas fa-angle-double-right',
          //   to: '/manage-lotto/pay'
          // }
        ]
      },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'ค้นหารายการแทง',
      //   route: '/ticket',
      //   fontIcon: 'fas fa-search-plus',
      //   items: [{
      //     name: 'ค้นหาโพย',
      //     fontIcon: 'fas fa-angle-double-right',
      //     to: '/ticket/search'
      //   }, {
      //     name: 'ค้นหาเลข',
      //     fontIcon: 'fas fa-angle-double-right',
      //     to: '/ticket/search-number'
      //   }, {
      //     name: 'ตรวจสอบคิว',
      //     fontIcon: 'fas fa-angle-double-right',
      //     to: '/ticket/queue'
      //   }]
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'ข่าวประกาศ',
      //   route: '/news',
      //   fontIcon: 'fas fa-bullhorn',
      //   items: [{
      //     name: 'ประกาศด่วน',
      //     fontIcon: 'fas fa-angle-double-right',
      //     to: '/news/express'
      //   }, {
      //     name: 'ประกาศหลัก',
      //     fontIcon: 'fas fa-angle-double-right',
      //     to: '/news/main'
      //   }]
      // },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: 'ตั้งค่า',
      //   route: '/setting',
      //   fontIcon: 'fas fa-cogs',
      //   items: [{
      //     name: 'ตั้งค่าการแทง',
      //     fontIcon: 'fas fa-angle-double-right',
      //     to: '/setting/bet'
      //   }, {
      //     name: 'จัดการข้อมูล',
      //     fontIcon: 'fas fa-angle-double-right',
      //     to: '/setting/data'
      //   }]
      // }
    ]
  }
]
