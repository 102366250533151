<template>
	<b-modal
		ref="add-lotto-market-modal"
		hide-footer
		title="สร้างงวดหวย"
		@show="resetModal"
    @hidden="resetModal"
	>
    <form
    	ref="form"
    	@submit.stop.prevent="handleSubmit"
    >

    	<b-form-group
        label="กลุ่ม"
        label-for="input-market-title"
      >
        <b-form-select name="groupId" v-model="input.groupId" :options="groupOptions" required></b-form-select>
      </b-form-group>
      <b-form-group
        label="หวย"
      >
        <b-form-select name="marketId" v-model="input.marketId" :options="marketOptions" :disabled="!input.groupId" required></b-form-select>
      </b-form-group>

      <b-form-group
        label="งวดวันที่"
      >
        <flat-pickr
          id="round-date"
          :config="{enableTime: false, enableSeconds: true, time_24hr: true, dateFormat: 'Y-m-d', minDate: min}"
          v-model="input.roundDate.date"
          class="form-control"
        />
      </b-form-group>

      <b-form-group
        label="เวลาเปิดรับแทง"
      >
        <flat-pickr
          id="round-open-time"
          :config="{enableTime: true, enableSeconds: false, time_24hr: true, dateFormat: 'Y-m-d H:i:S', maxDate: maxDate}"
          v-model="input.roundDate.open"
          class="form-control"
        />
      </b-form-group>

      <b-form-group
        label="เวลาปิดแทง"
      >
        <flat-pickr
          id="round-close-time"
          :config="{enableTime: true, enableSeconds: false, time_24hr: true, dateFormat: 'Y-m-d H:i:S', maxDate: maxDate}"
          v-model="input.roundDate.close"
          class="form-control"
        />
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
      	<b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
      	<b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'สร้างงวดหวย'}}</b-button>
      </div>
  	</form>
  </b-modal>
</template>
<script>
import Swal from 'sweetalert2'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import moment from '@/services/moment'
import LottoService from '@/services/lottoService'

const defaultInput = {
  groupId: null,
  marketId: null,
  roundDate: {
    date: null, //moment().format("YYYY-MM-DD"),
    open: null,
    close: null
  }
}

export default {
	name: 'LottoMarketTitleModal',
  components: {
    flatPickr
  },
	props: {
		isShow: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			input: JSON.parse(JSON.stringify({
        ...defaultInput,
        groupId: this.$store.state.globalMarket.groupId || null,
        marketId: this.$store.state.globalMarket.marketId || null,
      })),
			isProcess: false,
      needReload: false
		}
	},
  computed: {
    groupOptions() {
      let groups = [{
        value: null,
        text: '--- เลือกกลุ่มหวย ---'
      }]

      this.$store.state.marketGroups.forEach((g)=>{
        groups.push({
          value: g._id,
          text: g.groupTitle
        })
      })

      return groups
    },
    marketOptions() {
      let markets = [{
        value: null,
        text: '--- เลือกหวย ---'
      }]

      if(this.input.groupId) {
        this.$store.state.markets.filter((m)=>{
          return m.groupId === this.input.groupId
        })
        .forEach((m)=>{
          markets.push({
            value: m._id,
            text: m.marketTitle
          })
        })
      }
      return markets
    },
    marketId() {
      return this.input.marketId
    },
    min() {
      return moment().format("YYYY-MM-DD")
    },
    maxDate() {
      if(!this.input.roundDate.date)
        return moment().format("YYYY-MM-DD")

      return moment(this.input.roundDate.date).add(1, 'd').format("YYYY-MM-DD HH:mm")
    },
    roundDate() {
      return this.input?.roundDate?.date
    }
  },
	watch: {
		isShow() {
			if(this.isShow){
				this.showModal()
			}else{
				this.hideModal()
			}
		},
    marketId() {
      this.setMarketTime()
    },
    roundDate() {
      // if(!this.input.roundDate.open || !this.input.roundDate.close) {
      //   this.setMarketTime()
      // }
      this.setMarketTime()
    }
	},
	methods: {
		resetModal() {
      this.needReload = false
		},
		showModal() {
      this.input = JSON.parse(JSON.stringify({
        ...defaultInput,
        groupId: this.$store.state.globalMarket.groupId || null,
        marketId: this.$store.state.globalMarket.marketId || null,
      }))
      this.setMarketTime()
      this.$refs['add-lotto-market-modal'].show()
    },
    hideModal() {
      this.$refs['add-lotto-market-modal'].hide()
    },
    setMarketTime() {
      if(this.marketId) {
        const market = this.$store.state.markets.find((m)=>{
          return this.marketId === m._id
        })

        if(market && this.input?.roundDate?.date) {
          const open = moment(`${this.input.roundDate.date} ${market.openTime}`).format("YYYY-MM-DD HH:mm:ss")
          let close = moment(`${this.input.roundDate.date} ${market.closeTime}`).format("YYYY-MM-DD HH:mm:ss")

          /**
           * เวลาปิดอยู่ก่อนเวลาเปิด แสดงว่าเปิดรับแทงข้ามวัน
           * ให้เพิ่มเวลาปิด +1 วัน
           */
          if(moment(close).isBefore(open)) {
            close = moment(close).add(1, 'd').format("YYYY-MM-DD HH:mm:ss")
          }

          // this.input.roundDate.open = open
          this.input.roundDate.close = close
        }
      }
    },
		handleSubmit() {
      console.log('submit!')
      if(!this.input.roundDate.date) {
        return Swal.fire({
          text: 'กรุณาระบุงวดวันที่!',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      if(!this.input.roundDate.open) {
        return Swal.fire({
          text: 'กรุณาระบุเวลาเปิดรับ',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }

      LottoService.createRound(this.input)
      .then((response)=>{
        console.log(response)
        if(response.success) {
          Swal.fire({
            text: 'สร้างงวดเสร็จเรียบร้อย',
            icon: 'success',
            confirmButtonText: 'OK'
          })
          .then(()=>{

            this.$store.commit('updateGlobalMarket', {
              groupId: response.data.groupId,
              marketId: response.data.marketId,
              round: {
                roundId: response.data.id,
                roundDate: response.data.roundDate
              }
            })

            this.needReload = true
            this.hideModal()
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'สร้างงวดไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
		},
	},
	mounted() {
		this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
	}
}
</script>
<style lang="scss" scoped>

</style>
